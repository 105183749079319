import { ParsedResponse } from './util';
import {
  CreateMerchantOnboardingMutationVariables,
  CreateSupportNoteMutationVariables,
  DocumentInput,
  MerchantOnboarding,
  MerchantOnboardingInput,
  MerchantOnboardingQueryVariables,
} from './API/types';
import { generateClient } from 'aws-amplify/api';
import {
  createMerchantOnboarding,
  createSupportNote,
} from './API/graphql/mutations';
import { merchantOnboarding } from './API/graphql/queries';
import { GraphQLError } from 'graphql/error';

export const get = async (
  merchant_uid: string,
): Promise<ParsedResponse<MerchantOnboarding>> => {
  const client = generateClient();
  const variables: MerchantOnboardingQueryVariables = {
    merchant_uid,
  };
  try {
    const response = await client.graphql({
      query: merchantOnboarding,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.merchantOnboarding!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch merchant onboarding'] };
  }
};

export const update = async (
  merchant_onboarding: MerchantOnboardingInput,
  is_form_completed: boolean,
  docs: DocumentInput[] | null,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables: CreateMerchantOnboardingMutationVariables = {
    merchant_onboarding,
    is_form_completed,
    support_note: {
      support_docs: docs,
    },
  };
  try {
    const response = await client.graphql({
      query: createMerchantOnboarding,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    // @ts-ignore
    const errors = e?.errors?.map((err: GraphQLError) => err.message) || [
      'Unable to update merchant onboarding',
    ];
    return { data: null, errors };
  }
};

export const uploadOnboardingDocument = async (
  merchant_uid: string,
  ticket_id: number,
  docs: DocumentInput[],
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables: CreateSupportNoteMutationVariables = {
    merchant_uid,
    ticket_id: ticket_id.toString(),
    support_note: {
      support_docs: docs,
    },
  };
  try {
    const response = await client.graphql({
      query: createSupportNote,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to upload document'] };
  }
};
