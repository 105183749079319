/* global FreshworksWidget */
import React, {
  createContext,
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';

// @ts-ignore
import { closeSpinner, ModalSpinner } from '@paytheory/pay-theory-ui';
import {
  ErrorMessage,
  SuccessMessage,
} from '@paytheory/components.common.portal_head';
import { Loading } from '@paytheory/components.common.loading';

import { defaultOnboardingState } from './views/Onboarding/model';

import * as Login from './components/LoginComponents';

import './App.css';

import { Navigate, Route, Routes } from 'react-router-dom';
import AdminPortal from './components/AdminPortal';

import {
  withAuthenticator,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react';

import * as ROUTES from './constants/routes';
import {
  ConjunctiveOperator,
  DisputeStatus,
  Merchant,
  MerchantOnboarding,
  NotificationContextId,
  Notification,
  Operator,
  PaymentParameters,
  QueryPair,
  SortDirection,
} from './network/API/types';
import { generateMenu, useUserTimeout } from './util';
import { getOnboardingStatus } from './views/Transactions/model';
import { OnboardingState } from './components/OnboardingCard/model';
import type { AuthUser } from '@aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import * as network from './network';

// @ts-ignore
const Font = lazy(() => import('@paytheory/pay-theory-ui/dist/Font'));

const Transactions = lazy(() => import('./views/Transactions'));

const Settlements = lazy(() => import('./views/Settlements'));

const SettlementDetails = lazy(() => import('./views/SettlementDetails'));

const Settings = lazy(() => import('./views/Settings'));

const Chargebacks = lazy(() => import('./views/Disputes'));

const AllTransactions = lazy(() => import('./views/AllTransactions'));

const Invoices = lazy(() => import('./views/Invoices'));

const Recurring = lazy(() => import('./views/Recurring'));

const RecurringDetails = lazy(() => import('./views/RecurringDetails'));

const VirtualTerminal = lazy(() => import('./views/VirtualTerminal'));

const PaymentLinks = lazy(() => import('./views/PaymentLinks'));

const Onboarding = lazy(() => import('./views/Onboarding'));

type AppContextType = {
  signOut: () => void;
  merchantUID: string;
  merchant: Merchant | null;
  setMerchant: Dispatch<SetStateAction<Merchant | null>>;
  chargebackBadge: number | null;
  setChargebackBadge: Dispatch<SetStateAction<number | null>>;
  ErrorMessage: (message: any, stay?: boolean) => void;
  SuccessMessage: (message: any, stay?: boolean) => void;
  defaultParams: PaymentParameters | null;
  onboardingData: MerchantOnboarding | null;
  setOnboardingData: Dispatch<SetStateAction<MerchantOnboarding | null>>;
  getOnboardingData: (
    action?: (data: typeof defaultOnboardingState) => void,
  ) => void;
  onboardingStatus: OnboardingState;
  getMerchant: (
    uid: string,
    afterFetching?: (merchant?: Merchant) => void,
  ) => void;
  user: AuthUser | undefined;
  userFullName: string | undefined;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const App = (props: WithAuthenticatorProps) => {
  const { signOut, user } = props;
  const [merchantUID, setMerchantUID] = useState('');
  const [merchant, setMerchant] = useState<null | Merchant>(null);
  const [chargebackBadge, setChargebackBadge] = useState<number | null>(null);
  const [defaultParams, setDefaultParams] = useState<PaymentParameters | null>(
    null,
  );
  const [onboardingData, setOnboardingData] =
    useState<MerchantOnboarding | null>(null);
  const [userFullName, setUserFullName] = useState<string | undefined>(
    undefined,
  );
  // const [paged, setPaged] = useState<Object | null>(null);
  const typekit = process.env.REACT_APP_TYPEKIT;
  const onboardingStatus = getOnboardingStatus(merchant, onboardingData);

  const customSignOut = useCallback(async () => {
    setDefaultParams(null);
    if (signOut) signOut();
  }, [signOut]);

  // Set up the user timeout
  useUserTimeout(user, customSignOut);

  const getOnboardingData = useCallback(
    (merchant_uid: string) =>
      async (action?: (data: typeof defaultOnboardingState) => void) => {
        const response = await network.onboarding.get(merchant_uid);
        if (response.errors) {
          console.log(response.errors);
        } else {
          const copy = JSON.parse(
            JSON.stringify(defaultOnboardingState),
          ) as MerchantOnboarding;
          copy.merchant_uid = merchant_uid;
          setOnboardingData(response.data || copy);
          if (action) {
            action(response.data as unknown as typeof defaultOnboardingState);
          } else {
            closeSpinner();
          }
        }
      },
    [],
  );

  const getMerchant = useCallback(
    async (uid: string, afterFetch?: (merchant?: Merchant) => void) => {
      const response = await network.merchant.query(uid);
      if (response.errors) {
        console.log(response.errors);
        if (afterFetch) afterFetch();
        closeSpinner();
        ErrorMessage('Merchant not found');
      } else {
        const fetchedMerchant = response.data;
        setMerchant(fetchedMerchant);
        if (afterFetch) afterFetch(fetchedMerchant);
        if (
          onboardingData === null &&
          (!fetchedMerchant?.ach_active ||
            !fetchedMerchant?.card_active ||
            !fetchedMerchant?.cash_active)
        ) {
          getOnboardingData(uid)();
        } else {
          closeSpinner();
        }
      }
    },
    [onboardingData, getOnboardingData],
  );

  useEffect(() => {
    if (user) {
      fetchAuthSession()
        .then(session => {
          const merchant_uid =
            session.tokens?.idToken?.payload['custom:merchant_uid'];
          const full_name =
            session?.tokens?.idToken?.payload?.given_name +
            ' ' +
            session?.tokens?.idToken?.payload?.family_name;
          setUserFullName(full_name);
          setMerchantUID(merchant_uid as string);
          getMerchant(merchant_uid as string);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [getMerchant, user]);

  const getPaymentParams = useCallback(async () => {
    const response = await network.merchant.paymentParams();
    if (response.errors) {
      console.log(response.errors);
    } else {
      setDefaultParams(response.data);
    }
  }, []);

  const getPendingDisputes = useCallback(async () => {
    const query: QueryPair[] = [
      {
        key: 'evidence_last_send_date',
        operator: Operator.IS_NULL,
        conjunctive_operator: ConjunctiveOperator.AND_NEXT,
      },
      {
        key: 'status',
        operator: Operator.NOT_IN_LIST,
        in_values: [DisputeStatus.WON, DisputeStatus.LOST],
      },
    ];
    const params = {
      order: SortDirection.DESC,
      filter: query,
      limit: 100,
    };
    const response = await network.disputes.list(params);
    if (response.errors) {
      console.log(response.errors);
    } else {
      const chargebacks = response.data;
      const showMessage =
        chargebacks?.total_row_count &&
        chargebackBadge !== null &&
        chargebacks?.total_row_count > chargebackBadge;
      setChargebackBadge(chargebacks?.total_row_count || 0);
      if (showMessage) SuccessMessage('A new dispute has been created.');
    }
  }, [chargebackBadge]);

  const respondToSubscription = useCallback(
    (notification: Notification) => {
      if (notification.context_id === NotificationContextId.DISPUTE_CHANGE) {
        getPendingDisputes();
      }
    },
    [getPendingDisputes],
  );

  const subscribeToNotifications = useCallback(() => {
    network.subscriptions.subscribe(
      NotificationContextId.DISPUTE_CHANGE,
      merchantUID,
      respondToSubscription,
    );
  }, [merchantUID, respondToSubscription]);

  useEffect(() => {
    if (merchantUID) {
      subscribeToNotifications();
      getPendingDisputes();
      getPaymentParams();
    }
  }, [
    merchantUID,
    subscribeToNotifications,
    getPendingDisputes,
    getPaymentParams,
  ]);

  useEffect(() => {
    // @ts-ignore
    FreshworksWidget('hide', 'launcher');
  }, []);

  return (
    <div id="container">
      <div className="spinner-wrapper">
        <div className="modal-wrapper">
          <AppContext.Provider
            value={{
              signOut: customSignOut,
              merchantUID,
              merchant,
              setMerchant,
              chargebackBadge,
              setChargebackBadge,
              ErrorMessage,
              SuccessMessage,
              defaultParams,
              onboardingData,
              setOnboardingData,
              getOnboardingData: getOnboardingData(merchantUID),
              onboardingStatus,
              getMerchant,
              user,
              userFullName,
            }}>
            <Suspense fallback={<ModalSpinner on />}>
              <Routes>
                <Route
                  path={ROUTES.ONBOARDING}
                  // Show loading while we get the onboarding status and redirect if we are pending or boarded
                  element={
                    onboardingStatus === 'loading' ? (
                      <ModalSpinner on />
                    ) : ['pending', 'boarded', 'need_docs'].includes(
                        onboardingStatus,
                      ) ? (
                      <Navigate replace to={ROUTES.HOME} />
                    ) : (
                      <Onboarding />
                    )
                  }
                />
                <Route
                  path={'/'}
                  element={
                    <AdminPortal
                      paged={{}}
                      generateMenu={generateMenu(chargebackBadge ?? 0)}
                      logout={signOut}
                    />
                  }>
                  <Route
                    path=""
                    element={<Navigate replace to={ROUTES.HOME} />}
                  />
                  <Route
                    path={ROUTES.HOME}
                    element={merchant ? <Transactions /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.ALL_TRANSACTIONS}
                    element={merchant ? <AllTransactions /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.INVOICES}
                    element={merchant ? <Invoices /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.SETTLEMENTS}
                    element={merchant ? <Settlements /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.SETTLEMENTS_DETAILS}
                    element={merchant ? <SettlementDetails /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.DISPUTES}
                    element={merchant ? <Chargebacks /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.MERCHANT_SETTINGS}
                    element={merchant ? <Settings /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.RECURRING}
                    element={merchant ? <Recurring /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.RECURRING_DETAILS}
                    element={merchant ? <RecurringDetails /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.VIRTUAL_TERMINAL}
                    element={merchant ? <VirtualTerminal /> : <Loading />}
                  />
                  <Route
                    path={ROUTES.PAYMENT_LINKS}
                    element={merchant ? <PaymentLinks /> : <Loading />}
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to={ROUTES.HOME} />}
                  />
                </Route>
              </Routes>
              <Font typekit={typekit} />
            </Suspense>
            <ModalSpinner />
          </AppContext.Provider>
        </div>
      </div>
    </div>
  );
};

// @ts-ignore
export default withAuthenticator(App, {
  loginMechanisms: ['email'],
  hideSignUp: true,
  components: {
    Header: Login.Header,
    SignIn: {
      Header: Login.SignInHeader,
    },
  },
});
