/* global FreshworksWidget */
import React, { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import './styles.css';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from '@paytheory/components.common.global_style';

const HideFreshworks = () => {
  useEffect(() => {
    // @ts-ignore
    FreshworksWidget('hide', 'launcher');
  }, []);
  return <div />;
};

const apiUrl = `https://${process.env.REACT_APP_PARTNER}.sdk.${process.env.REACT_APP_STAGE}.com/index.js`;
const scriptElement = document.createElement('script');
scriptElement.src = apiUrl;
document.head.appendChild(scriptElement);

const internalEndpoint = `https://internal.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`;

Amplify.configure({
  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL!, //'us-east-1:ffd0e720-da44-45ab-ac61-66422d32a552',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL!, //'us-east-1_ut0KvX8ix',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT!, //'7275mnpka5v5d0nmk1iav5de8m',
    },
  },
  API: {
    GraphQL: {
      endpoint: internalEndpoint,
      region: process.env.REACT_APP_COGNITO_REGION,
      defaultAuthMode: 'userPool',
    },
  },
});

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <Router>
    <StrictMode>
      <GlobalStyle />
      <HideFreshworks />
      {/*@ts-ignore*/}
      <App />
    </StrictMode>
  </Router>,
);
serviceWorker.unregister();
