import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  CreateOfflineTransactionMutationVariables,
  Invoice,
  Invoices,
  InvoicesQuery,
  InvoicesQueryVariables,
  InvoiceUpdateInput,
  MoveDirection,
  OfflineTransaction,
  OfflineTransactionType,
  Operator,
  QueryPair,
  SortDirection,
  UpdateInvoiceMutationVariables,
} from './API/types';
import { GeneratedQuery, ParsedResponse } from './util';
import { generateClient } from 'aws-amplify/api';
import {
  updateInvoice,
  deleteInvoice as deleteInvoiceMutation,
  createOfflineTransaction,
  createInvoiceEmail,
} from './API/graphql/mutations';

const listInvoices =
  `query ListInvoices($direction: MoveDirection = FORWARD, $limit: Int = 10, $offset: String = "", $offset_id: String = "", $query: SqlQuery = {}, $payor_query: [QueryPair] = {}) {
  invoices(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      merchant_invoice_number
      payor(query_list: $payor_query) {
        payor_id
        full_name
        address_line1
        address_line2
        country
        region
        city
        postal_code
        email
        phone
      }
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
        is_secure
        require_payor_address
        security_pin
      }
      account_code
      reference
      status
      total_paid_amount
    }
    total_row_count
  }
}
` as GeneratedQuery<InvoicesQueryVariables, InvoicesQuery>;

export const list = async (
  order: SortDirection,
  offset: Invoice | null,
  limit: number,
  filter: { invoiceQuery: QueryPair[]; payorQuery: QueryPair[] },
  direction: MoveDirection,
): Promise<ParsedResponse<Invoices>> => {
  const client = generateClient();
  const sort = [{ key: 'created_date', direction: order }];
  const queryObject = {
    query_list: filter.invoiceQuery,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.invoice_id,
    offset: offset?.created_date,
    limit: limit,
    direction: direction,
    payor_query: filter.payorQuery,
  };
  try {
    const response = await client.graphql({
      query: listInvoices,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.invoices!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch invoices'] };
  }
};

const getInvoice = `query GetInvoice($query: SqlQuery = {}) {
  invoices(query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      merchant_invoice_number
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
      }
      payor {
        email
        full_name
        phone
        address_line1
        address_line2
        city
        country
        postal_code
        region
        }
      account_code
      reference
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
        is_secure
        require_payor_address
        security_pin
      }
      status
      total_paid_amount
    }
  }
}
` as GeneratedQuery<InvoicesQueryVariables, InvoicesQuery>;

export const get = async (
  invoiceId: string,
): Promise<ParsedResponse<Invoice>> => {
  const client = generateClient();
  const query = {
    query_list: [
      {
        key: 'invoice_id',
        value: `%${invoiceId}%`,
        operator: Operator.LIKE,
      },
    ],
  };
  const variables = {
    query: query,
  };
  try {
    const response = await client.graphql({
      query: getInvoice,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    if (
      response.data.invoices &&
      response.data.invoices.items &&
      response.data.invoices.items.length > 0
    ) {
      return { data: response.data.invoices.items[0]!, errors: null };
    }
    return { data: null, errors: ['Unable to find invoice'] };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch invoice'] };
  }
};

const createInvoiceString = `mutation CreateInvoice($input: InvoiceInput!) {
  createInvoice(input: $input) {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      merchant_invoice_number
      payor {
        full_name
        phone
        payor_id
      }
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
        is_secure
        require_payor_address
        security_pin
      }
      account_code
      reference
      status
      total_paid_amount
  }
}` as GeneratedQuery<CreateInvoiceMutationVariables, CreateInvoiceMutation>;

export const createInvoice = async (
  variables: CreateInvoiceMutationVariables,
): Promise<ParsedResponse<Invoice>> => {
  const client = generateClient();
  try {
    const response = await client.graphql({
      query: createInvoiceString,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.createInvoice!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to create invoice'] };
  }
};

export const update = async (
  invoice_id: string,
  input: InvoiceUpdateInput,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables: UpdateInvoiceMutationVariables = {
    invoice_id,
    invoice_update_input: input,
  };
  try {
    const response = await client.graphql({
      query: updateInvoice,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.updateInvoice!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to update invoice'] };
  }
};

export const deleteInvoice = async (
  invoice_id: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    invoice_id,
  };
  try {
    const response = await client.graphql({
      query: deleteInvoiceMutation,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.deleteInvoice!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to delete invoice'] };
  }
};

export const createOfflinePayment = async (
  amount: number,
  invoiceId: string,
  note: string,
  transactionDate: string,
  type: OfflineTransactionType,
): Promise<ParsedResponse<OfflineTransaction>> => {
  const client = generateClient();
  const variables: CreateOfflineTransactionMutationVariables = {
    input: {
      amount: amount,
      invoice_id: invoiceId,
      note: note,
      transaction_date: transactionDate,
      type: type,
    },
  };
  try {
    const response = await client.graphql({
      query: createOfflineTransaction,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.createOfflineTransaction!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to create offline transaction'] };
  }
};

export const sendEmail = async (
  invoiceId: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    invoice_id: invoiceId,
  };
  try {
    const response = await client.graphql({
      query: createInvoiceEmail,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.createInvoiceEmail!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to send email'] };
  }
};
