import { GeneratedQuery, ListParams, ParsedResponse } from './util';
import {
  AwsS3Action,
  Dispute,
  DisputeEvidence,
  DisputeEvidenceTokensQueryVariables,
  Disputes,
  DisputesQuery,
  DisputesQueryVariables,
  QueryPair,
} from './API/types';
import { generateClient } from 'aws-amplify/api';
import { disputeEvidence, disputeEvidenceTokens } from './API/graphql/queries';
import {
  createSendDisputeEvidence,
  deleteDisputeEvidence,
} from './API/graphql/mutations';

const listDisputes =
  `query MyQuery($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection) {
  disputes(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      amount
      dispute_date
      dispute_id
      evidence_last_send_date
      expiration_date
      merchant_uid
      reason
      reason_message
      settlement_deposit_batch
      settlement_withdrawal_batch
      status
      updated_date
      transaction {
        merchant_uid
        transaction_id
        device_id
        account_code
        gross_amount
        net_amount
        refunded_amount
        fees
        currency
        reference
        settlement_batch
        status
        dispute_status
        is_settled
        transaction_date
        transaction_type
        refund_voidable
        fee_mode
        timezone
        metadata
        payment_method {
          payment_method_id
          merchant_uid
          payment_type
          wallet_type
          last_four
          exp_date
          card_brand
          full_name
          address_line1
          address_line2
          country
          region
          city
          postal_code
          is_active
          payor {
            payor_id
            merchant_uid
            full_name
            address_line1
            address_line2
            country
            region
            city
            postal_code
            email
            phone
          }
        }
      }
    }
    total_row_count
  }
}
` as GeneratedQuery<DisputesQueryVariables, DisputesQuery>;

export const list = async (
  params: ListParams<Dispute, QueryPair[]>,
): Promise<ParsedResponse<Disputes>> => {
  const client = generateClient();
  const sort = [{ key: 'dispute_date', direction: params.order }];
  const queryObject = {
    query_list: params.filter,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: params.offset?.dispute_id,
    offset: params.offset?.dispute_date,
    limit: params.limit,
    direction: params.direction,
  };
  try {
    const response = await client.graphql({
      query: listDisputes,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.disputes!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch disputes'] };
  }
};

export const listEvidence = async (
  dispute_id: string,
  merchant_uid: string,
): Promise<ParsedResponse<Array<DisputeEvidence | null>>> => {
  const client = generateClient();
  const variables = {
    dispute_id: dispute_id,
    merchant_uid: merchant_uid,
  };
  try {
    const response = await client.graphql({
      query: disputeEvidence,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.disputeEvidence!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch dispute evidence'] };
  }
};

export const downloadOrUploadEvidence = async (params: {
  dispute_id: string;
  filenames: string[];
  merchant_uid: string;
  mime_types?: string[] | null;
  action: AwsS3Action;
}): Promise<ParsedResponse<Array<string | null>>> => {
  const client = generateClient();
  const variables: DisputeEvidenceTokensQueryVariables = {
    action: params.action,
    dispute_id: params.dispute_id,
    filenames: params.filenames,
    mime_types: params.mime_types,
    merchant_uid: params.merchant_uid,
  };
  try {
    const response = await client.graphql({
      query: disputeEvidenceTokens,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.disputeEvidenceTokens!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to download dispute evidence'] };
  }
};

export const deleteEvidence = async (
  dispute_id: string,
  filename: string,
  merchant_uid: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    dispute_id: dispute_id,
    filename: filename,
    merchant_uid: merchant_uid,
  };
  try {
    const response = await client.graphql({
      query: deleteDisputeEvidence,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to delete dispute evidence'] };
  }
};

export const sendEvidence = async (
  dispute_id: string,
  merchant_uid: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    dispute_id: dispute_id,
    merchant_uid: merchant_uid,
  };
  try {
    const response = await client.graphql({
      query: createSendDisputeEvidence,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to send dispute evidence'] };
  }
};
