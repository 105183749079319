//authentication
export const LANDING = '/';
export const ACCOUNT = '/account';

//client functionality
export const PAYMENTS = '/payments';
export const MERCHANT_SETTINGS = '/settings';
export const HOME = PAYMENTS;
export const SETTLEMENTS = '/settlements';
export const SETTLEMENTS_DETAILS = '/settlements/details/:key';
export const DISPUTES = '/disputes';
export const ALL_TRANSACTIONS = '/all-transactions';
export const INVOICES = '/invoices';
export const RECURRING = '/recurring';
export const RECURRING_DETAILS = '/recurring/details/:id';
export const VIRTUAL_TERMINAL = '/virtual-terminal';
export const PAYMENT_LINKS = '/payment-links';

export const ONBOARDING = '/onboarding';

//error
export const VERIFICATION_FAILED = '/verification-failed';
export const NOT_FOUND = '/not-found';
export const UNAUTHORIZED = '/unauthorized';
