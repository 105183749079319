/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../types';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const authorizations = /* GraphQL */ `query Authorizations(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  authorizations(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      additional_purchase_data {
        level3_data_line_item {
          item_code
          item_description
          item_qty_exp
          prod_code
          qty
          tax_amount
          tax_ind
          tax_rate
          tax_rt_exp
          tax_type_id
          unit_cost
          unit_of_msure
          __typename
        }
        level3_data_summary {
          dest_postal_code
          discnt_amt
          duty_amt
          frght_amt
          order_num
          prod_desc
          purch_idfr
          tax_amt
          tax_ind
          __typename
        }
        __typename
      }
      amount
      authorization_date
      authorization_id
      avs_status
      captured_amount
      currency
      device_id
      expiration_date
      failure_reasons
      fee_mode
      fees
      invoice {
        account_code
        created_date
        currency
        due_by
        fee_mode
        invoice_amount
        invoice_date
        invoice_description
        invoice_id
        invoice_name
        merchant_invoice_number
        merchant_uid
        metadata
        offline_transactions {
          amount
          instance_id
          invoice_id
          note
          transaction_date
          type
          __typename
        }
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        reference
        settings {
          accepted_payment_methods {
            ach
            card
            cash
            __typename
          }
          is_secure
          require_payor_address
          security_pin
          __typename
        }
        status
        total_paid_amount
        __typename
      }
      merchant_uid
      metadata
      payment_method {
        address_line1
        address_line2
        bank_account_type
        bank_code
        barcode_id
        card_brand
        card_type
        city
        country
        exp_date
        full_name
        is_active
        issuing_country_code
        last_four
        merchant_uid
        metadata
        payment_method_id
        payment_type
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        postal_code
        region
        wallet_type
        __typename
      }
      reference
      sale_id
      status
      timezone
      transaction_id
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuthorizationsQueryVariables,
  APITypes.AuthorizationsQuery
>;
export const backOfficeKey = /* GraphQL */ `query BackOfficeKey(
  $merchant_uid: String!
  $entity_level: BackofficeKeyEntityLevel!
) {
  backOfficeKey(merchant_uid: $merchant_uid, entity_level: $entity_level) {
    created_date
    enabled
    entity_level
    key_name
    last_accessed_date
    merchant_uid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BackOfficeKeyQueryVariables,
  APITypes.BackOfficeKeyQuery
>;
export const barcode =
  /* GraphQL */ `query Barcode($merchant_uid: ID!, $barcode_id: String!) {
  barcode(merchant_uid: $merchant_uid, barcode_id: $barcode_id) {
    account_code
    amount
    barcode_id
    barcode_url
    email_or_phone
    expiration_date
    fees
    full_name
    invoice_id
    merchant_uid
    metadata
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    payor_id
    reference
    sale_id
    __typename
  }
}
` as GeneratedQuery<APITypes.BarcodeQueryVariables, APITypes.BarcodeQuery>;
export const defaultPaymentParameters =
  /* GraphQL */ `query DefaultPaymentParameters {
  defaultPaymentParameters {
    denied_after_date
    denied_before_date
    enabled
    expires_in
    maximum_amount
    maximum_occurrence
    minimum_amount
    payment_parameters_name
    payment_parameters_type
    recurrence_period
    validation_link_url
    __typename
  }
}
` as GeneratedQuery<
    APITypes.DefaultPaymentParametersQueryVariables,
    APITypes.DefaultPaymentParametersQuery
  >;
export const devices = /* GraphQL */ `query Devices(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  devices(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      device_id
      device_description
      device_name
      device_properties
      is_active
      merchant_uid
      processor
      processor_device_id
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.DevicesQueryVariables, APITypes.DevicesQuery>;
export const disputeEvidence =
  /* GraphQL */ `query DisputeEvidence($dispute_id: String!, $merchant_uid: ID) {
  disputeEvidence(dispute_id: $dispute_id, merchant_uid: $merchant_uid) {
    dispute_id
    evidence_date
    filename
    merchant_uid
    status
    updated_date
    __typename
  }
}
` as GeneratedQuery<
    APITypes.DisputeEvidenceQueryVariables,
    APITypes.DisputeEvidenceQuery
  >;
export const disputeEvidenceTokens = /* GraphQL */ `query DisputeEvidenceTokens(
  $action: AwsS3Action!
  $dispute_id: String!
  $filenames: [String]!
  $mime_types: [String!]
  $merchant_uid: ID
) {
  disputeEvidenceTokens(
    action: $action
    dispute_id: $dispute_id
    filenames: $filenames
    mime_types: $mime_types
    merchant_uid: $merchant_uid
  )
}
` as GeneratedQuery<
  APITypes.DisputeEvidenceTokensQueryVariables,
  APITypes.DisputeEvidenceTokensQuery
>;
export const disputes = /* GraphQL */ `query Disputes(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  disputes(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      amount
      dispute_date
      dispute_id
      evidence_last_send_date
      expiration_date
      merchant_uid
      status
      reason
      reason_message
      settlement_deposit_batch
      settlement_withdrawal_batch
      transaction {
        account_code
        ach_return_details {
          return_code
          return_details
          transfer_type
          __typename
        }
        additional_purchase_data {
          level3_data_line_item {
            item_code
            item_description
            item_qty_exp
            prod_code
            qty
            tax_amount
            tax_ind
            tax_rate
            tax_rt_exp
            tax_type_id
            unit_cost
            unit_of_msure
            __typename
          }
          level3_data_summary {
            dest_postal_code
            discnt_amt
            duty_amt
            frght_amt
            order_num
            prod_desc
            purch_idfr
            tax_amt
            tax_ind
            __typename
          }
          __typename
        }
        authorization_id
        avs_status
        currency
        device_id
        dispute_status
        failure_reasons
        fee_mode
        fees
        flag_for_review
        gross_amount
        invoice {
          account_code
          created_date
          currency
          due_by
          fee_mode
          invoice_amount
          invoice_date
          invoice_description
          invoice_id
          invoice_name
          merchant_invoice_number
          merchant_uid
          metadata
          offline_transactions {
            amount
            instance_id
            invoice_id
            note
            transaction_date
            type
            __typename
          }
          payor {
            address_line1
            address_line2
            city
            country
            email
            full_name
            merchant_uid
            metadata
            payor_id
            phone
            postal_code
            region
            __typename
          }
          reference
          settings {
            is_secure
            require_payor_address
            security_pin
            __typename
          }
          status
          total_paid_amount
          __typename
        }
        is_settled
        merchant {
          ach_active
          card_active
          cash_active
          country_code
          is_system
          merchant_name
          merchant_uid
          parent_merchant_uid
          submitted_onboarding
          updated_row_at
          __typename
        }
        merchant_uid
        metadata
        net_amount
        parent_id
        payment_method {
          address_line1
          address_line2
          bank_account_type
          bank_code
          barcode_id
          card_brand
          card_type
          city
          country
          exp_date
          full_name
          is_active
          issuing_country_code
          last_four
          merchant_uid
          metadata
          payment_method_id
          payment_type
          payor {
            address_line1
            address_line2
            city
            country
            email
            full_name
            merchant_uid
            metadata
            payor_id
            phone
            postal_code
            region
            __typename
          }
          postal_code
          region
          wallet_type
          __typename
        }
        recurring {
          account_code
          amount_per_payment
          created_date
          currency
          fee_mode
          fee_per_payment
          is_active
          is_processing
          recurring_id
          merchant_uid
          metadata
          mute_all_emails
          next_payment_date
          payment_interval
          payment_method {
            address_line1
            address_line2
            bank_account_type
            bank_code
            barcode_id
            card_brand
            card_type
            city
            country
            exp_date
            full_name
            is_active
            issuing_country_code
            last_four
            merchant_uid
            metadata
            payment_method_id
            payment_type
            postal_code
            region
            wallet_type
            __typename
          }
          payor {
            address_line1
            address_line2
            city
            country
            email
            full_name
            merchant_uid
            metadata
            payor_id
            phone
            postal_code
            region
            __typename
          }
          prev_payment_date
          recurring_description
          recurring_name
          reference
          remaining_payments
          status
          total_amount_per_payment
          __typename
        }
        reference
        refund_reason {
          reason_code
          reason_details
          __typename
        }
        refund_voidable
        refunded_amount
        sale_id
        settlement_batch
        status
        timezone
        transaction_date
        transaction_id
        transaction_type
        updated_row_at
        __typename
      }
      updated_date
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.DisputesQueryVariables, APITypes.DisputesQuery>;
export const errorText =
  /* GraphQL */ `query ErrorText($language_code: String!, $error_codes: [String]!) {
  errorText(language_code: $language_code, error_codes: $error_codes)
}
` as GeneratedQuery<APITypes.ErrorTextQueryVariables, APITypes.ErrorTextQuery>;
export const generateCSV =
  /* GraphQL */ `query GenerateCSV($csvInput: CSVInput!) {
  generateCSV(csvInput: $csvInput) {
    bucket_name
    filepaths
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GenerateCSVQueryVariables,
    APITypes.GenerateCSVQuery
  >;
export const hostedCheckoutPage =
  /* GraphQL */ `query HostedCheckoutPage($session_id: String!) {
  hostedCheckoutPage(session_id: $session_id) {
    accepted_payment_methods
    account_code
    amount
    api_key
    call_to_action
    checkout_type
    country_code
    currency
    fee_mode
    invoice_id
    is_active
    merchant_name
    merchant_uid
    metadata
    origin
    payment_description
    payment_name
    payment_parameters
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    recurring_id
    require_phone
    __typename
  }
}
` as GeneratedQuery<
    APITypes.HostedCheckoutPageQueryVariables,
    APITypes.HostedCheckoutPageQuery
  >;
export const invoiceCheckoutPage =
  /* GraphQL */ `query InvoiceCheckoutPage($invoice_id: String!) {
  invoiceCheckoutPage(invoice_id: $invoice_id) {
    account_code
    api_key
    country_code
    currency
    fee_mode
    invoice_amount
    invoice_description
    invoice_id
    invoice_name
    merchant_name
    merchant_uid
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    reference
    settings {
      accepted_payment_methods {
        ach
        card
        cash
        __typename
      }
      is_secure
      require_payor_address
      security_pin
      __typename
    }
    status
    total_paid_amount
    __typename
  }
}
` as GeneratedQuery<
    APITypes.InvoiceCheckoutPageQueryVariables,
    APITypes.InvoiceCheckoutPageQuery
  >;
export const invoices = /* GraphQL */ `query Invoices(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  invoices(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      created_date
      currency
      due_by
      fee_mode
      invoice_amount
      invoice_date
      invoice_description
      invoice_id
      invoice_name
      merchant_invoice_number
      merchant_uid
      metadata
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      reference
      settings {
        accepted_payment_methods {
          ach
          card
          cash
          __typename
        }
        is_secure
        require_payor_address
        security_pin
        __typename
      }
      status
      total_paid_amount
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.InvoicesQueryVariables, APITypes.InvoicesQuery>;
export const merchant =
  /* GraphQL */ `query Merchant($merchant_uid: String, $merchant_name: String) {
  merchant(merchant_uid: $merchant_uid, merchant_name: $merchant_name) {
    ach_active
    api_key
    card_active
    cash_active
    country_code
    fee_matrix {
      ach {
        merchant_fee {
          basis_points
          fixed
          max_fee
          __typename
        }
        service_fee {
          basis_points
          fixed
          max_fee
          min_fee
          __typename
        }
        __typename
      }
      ach_return_fee
      ach_return_disputed_fee
      business_credit {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      business_debit {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      amex {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      card_account_updater
      cash
      chargeback_fee
      credit_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      debit_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      discover {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      interchange_plus
      international_card_basis
      mastercard {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      merchant_uid
      prepaid_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      service_fee_enabled
      visa {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      __typename
    }
    fee_model {
      interchange_plus
      merchant_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      service_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      service_fee_min {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      __typename
    }
    is_system
    merchant_name
    merchant_uid
    parent_merchant_uid
    settings {
      contact_email
      contact_phone
      facebook
      instagram
      linkedin
      tiktok
      twitter
      website
      __typename
    }
    submitted_onboarding
    __typename
  }
}
` as GeneratedQuery<APITypes.MerchantQueryVariables, APITypes.MerchantQuery>;
export const merchantOnboarding =
  /* GraphQL */ `query MerchantOnboarding($merchant_uid: ID!) {
  merchantOnboarding(merchant_uid: $merchant_uid) {
    banks {
      account_number
      account_type
      account_uses
      bank_code
      bank_name
      institution_number
      name
      transit_number
      __typename
    }
    business {
      additional_underwriting_data {
        annual_ach_volume
        average_ach_transfer_amount
        average_card_transfer_amount
        business_description
        card_volume_distribution {
          card_present_percentage
          ecommerce_percentage
          __typename
        }
        credit_check_allowed
        credit_check_ip_address
        credit_check_timestamp
        credit_check_user_agent
        merchant_agreement_accepted
        merchant_agreement_ip_address
        merchant_agreement_timestamp
        merchant_agreement_user_agent
        statement_descriptor
        refund_policy
        volume_distribution_by_business_type {
          business_to_business_volume_percentage
          business_to_consumer_volume_percentage
          other_volume_percentage
          __typename
        }
        __typename
      }
      underwriting_data {
        annual_card_volume
        business_address {
          city
          country
          line1
          line2
          postal_code
          region
          __typename
        }
        has_accepted_credit_cards_previously
        business_name
        business_type
        delivery_0_days_percent
        delivery_1_to_7_days_percent
        delivery_8_to_14_days_percent
        delivery_15_to_30_days_percent
        delivery_over_30_days_percent
        doing_business_as
        incorporation_date
        incorporation_state
        max_transaction_amount
        mcc
        phone
        prod_serv_sold
        ownership_type
        tax_id
        url
        __typename
      }
      __typename
    }
    business_owners {
      address {
        city
        country
        line1
        line2
        postal_code
        region
        __typename
      }
      dob
      email
      first_name
      last_name
      middle_name
      nationality
      owner_position
      percentage_ownership
      phone
      primary_owner
      tax_id
      title
      uid
      __typename
    }
    country_code_of_operation
    is_locked
    merchant_uid
    needs_docs
    ticket_id
    user_email
    user_full_name
    __typename
  }
}
` as GeneratedQuery<
    APITypes.MerchantOnboardingQueryVariables,
    APITypes.MerchantOnboardingQuery
  >;
export const merchants = /* GraphQL */ `query Merchants(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  merchants(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      ach_active
      card_active
      cash_active
      country_code
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.MerchantsQueryVariables, APITypes.MerchantsQuery>;
export const missedRecurringPaymentData =
  /* GraphQL */ `query MissedRecurringPaymentData($recurring_id: String!) {
  missedRecurringPaymentData(recurring_id: $recurring_id) {
    fee
    number_of_payments_missed
    total_amount_owed
    __typename
  }
}
` as GeneratedQuery<
    APITypes.MissedRecurringPaymentDataQueryVariables,
    APITypes.MissedRecurringPaymentDataQuery
  >;
export const nativeApps = /* GraphQL */ `query NativeApps {
  nativeApps {
    app_id
    description
    device_check_key
    device_check_kid
    merchant_uid
    platform
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NativeAppsQueryVariables,
  APITypes.NativeAppsQuery
>;
export const paymentLinkCheckoutPage =
  /* GraphQL */ `query PaymentLinkCheckoutPage($link_id: String!) {
  paymentLinkCheckoutPage(link_id: $link_id) {
    accepted_payment_methods
    account_code
    amount
    amount_is_variable
    api_key
    call_to_action
    country_code
    currency
    custom_success_message
    fee_mode
    is_active
    max_amount
    merchant_name
    merchant_uid
    min_amount
    payment_description
    payment_name
    redirect_url
    require_phone
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PaymentLinkCheckoutPageQueryVariables,
    APITypes.PaymentLinkCheckoutPageQuery
  >;
export const paymentLinks = /* GraphQL */ `query PaymentLinks(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  paymentLinks(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      accepted_payment_methods
      account_code
      amount
      amount_is_variable
      call_to_action
      created_date
      currency
      custom_success_message
      fee_mode
      is_active
      link_id
      link_name
      link_url
      max_amount
      merchant_uid
      min_amount
      payment_name
      payment_description
      redirect_url
      require_phone
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentLinksQueryVariables,
  APITypes.PaymentLinksQuery
>;
export const paymentMethodTokens = /* GraphQL */ `query PaymentMethodTokens(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  paymentMethodTokens(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentMethodTokensQueryVariables,
  APITypes.PaymentMethodTokensQuery
>;
export const payors = /* GraphQL */ `query Payors(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  payors(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.PayorsQueryVariables, APITypes.PayorsQuery>;
export const recurringUpdatePage =
  /* GraphQL */ `query RecurringUpdatePage($recurring_hash: String!) {
  recurringUpdatePage(recurring_hash: $recurring_hash) {
    amount
    api_key
    hash
    merchant_name
    payor_id
    payor_name
    recurring_description
    recurring_name
    __typename
  }
}
` as GeneratedQuery<
    APITypes.RecurringUpdatePageQueryVariables,
    APITypes.RecurringUpdatePageQuery
  >;
export const recurringPayments = /* GraphQL */ `query RecurringPayments(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  recurringPayments(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      amount_per_payment
      created_date
      currency
      fee_mode
      fee_per_payment
      is_active
      is_processing
      recurring_id
      merchant_uid
      metadata
      mute_all_emails
      next_payment_date
      payment_interval
      payment_method {
        address_line1
        address_line2
        bank_account_type
        bank_code
        barcode_id
        card_brand
        card_type
        city
        country
        exp_date
        full_name
        is_active
        issuing_country_code
        last_four
        merchant_uid
        metadata
        payment_method_id
        payment_type
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        postal_code
        region
        wallet_type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      prev_payment_date
      recurring_description
      recurring_name
      reference
      remaining_payments
      status
      total_amount_per_payment
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecurringPaymentsQueryVariables,
  APITypes.RecurringPaymentsQuery
>;
export const serviceFee = /* GraphQL */ `query ServiceFee(
  $merchant_uid: String!
  $amount: Int!
  $bank_id: String
  $payment_type: String
  $is_ach: Boolean
) {
  serviceFee(
    merchant_uid: $merchant_uid
    amount: $amount
    bank_id: $bank_id
    payment_type: $payment_type
    is_ach: $is_ach
  ) {
    fee_limit_reached
    fee
    total
    adjusted_total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServiceFeeQueryVariables,
  APITypes.ServiceFeeQuery
>;
export const serviceFeeAmount =
  /* GraphQL */ `query ServiceFeeAmount($merchant_uid: String!, $amount: Int!) {
  serviceFeeAmount(merchant_uid: $merchant_uid, amount: $amount) {
    ach {
      fee_limit_reached
      fee
      total
      adjusted_total
      __typename
    }
    card {
      fee_limit_reached
      fee
      total
      adjusted_total
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ServiceFeeAmountQueryVariables,
    APITypes.ServiceFeeAmountQuery
  >;
export const settlements = /* GraphQL */ `query Settlements(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  settlements(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      currency
      gross_amount
      gross_amount_64bit
      merchant_uid
      net_amount
      net_amount_64bit
      settlement_batch
      settlement_date
      status
      transaction_debit_count
      transaction_dispute_count
      transaction_reversal_count
      transfer_date
      total_adjustments
      total_adjustments_64bit
      total_fees
      total_fees_64bit
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SettlementsQueryVariables,
  APITypes.SettlementsQuery
>;
export const supportConversation =
  /* GraphQL */ `query SupportConversation($merchant_uid: ID!, $ticket_id: ID!) {
  supportConversation(merchant_uid: $merchant_uid, ticket_id: $ticket_id) {
    support_notes {
      body
      support_docs {
        attachment_id
        doc_type
        name
        __typename
      }
      __typename
    }
    ticket_id
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SupportConversationQueryVariables,
    APITypes.SupportConversationQuery
  >;
export const transactions = /* GraphQL */ `query Transactions(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  transactions(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      ach_return_details {
        return_code
        return_details
        transfer_type
        __typename
      }
      additional_purchase_data {
        level3_data_line_item {
          item_code
          item_description
          item_qty_exp
          prod_code
          qty
          tax_amount
          tax_ind
          tax_rate
          tax_rt_exp
          tax_type_id
          unit_cost
          unit_of_msure
          __typename
        }
        level3_data_summary {
          dest_postal_code
          discnt_amt
          duty_amt
          frght_amt
          order_num
          prod_desc
          purch_idfr
          tax_amt
          tax_ind
          __typename
        }
        __typename
      }
      authorization_id
      avs_status
      currency
      device_id
      dispute_status
      failure_reasons
      fee_mode
      fees
      flag_for_review
      gross_amount
      invoice {
        account_code
        created_date
        currency
        due_by
        fee_mode
        invoice_amount
        invoice_date
        invoice_description
        invoice_id
        invoice_name
        merchant_invoice_number
        merchant_uid
        metadata
        offline_transactions {
          amount
          instance_id
          invoice_id
          note
          transaction_date
          type
          __typename
        }
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        reference
        settings {
          accepted_payment_methods {
            ach
            card
            cash
            __typename
          }
          is_secure
          require_payor_address
          security_pin
          __typename
        }
        status
        total_paid_amount
        __typename
      }
      is_settled
      merchant {
        ach_active
        card_active
        cash_active
        country_code
        is_system
        merchant_name
        merchant_uid
        parent_merchant_uid
        submitted_onboarding
        updated_row_at
        __typename
      }
      merchant_uid
      metadata
      net_amount
      parent_id
      payment_method {
        address_line1
        address_line2
        bank_account_type
        bank_code
        barcode_id
        card_brand
        card_type
        city
        country
        exp_date
        full_name
        is_active
        issuing_country_code
        last_four
        merchant_uid
        metadata
        payment_method_id
        payment_type
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        postal_code
        region
        wallet_type
        __typename
      }
      recurring {
        account_code
        amount_per_payment
        created_date
        currency
        fee_mode
        fee_per_payment
        is_active
        is_processing
        recurring_id
        merchant_uid
        metadata
        mute_all_emails
        next_payment_date
        payment_interval
        payment_method {
          address_line1
          address_line2
          bank_account_type
          bank_code
          barcode_id
          card_brand
          card_type
          city
          country
          exp_date
          full_name
          is_active
          issuing_country_code
          last_four
          merchant_uid
          metadata
          payment_method_id
          payment_type
          payor {
            address_line1
            address_line2
            city
            country
            email
            full_name
            merchant_uid
            metadata
            payor_id
            phone
            postal_code
            region
            __typename
          }
          postal_code
          region
          wallet_type
          __typename
        }
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        prev_payment_date
        recurring_description
        recurring_name
        reference
        remaining_payments
        status
        total_amount_per_payment
        __typename
      }
      reference
      refund_reason {
        reason_code
        reason_details
        __typename
      }
      refund_voidable
      refunded_amount
      sale_id
      settlement_batch
      status
      timezone
      transaction_date
      transaction_id
      transaction_type
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsQueryVariables,
  APITypes.TransactionsQuery
>;
export const users =
  /* GraphQL */ `query Users($user_pool: UserPool!, $merchant_uid: String) {
  users(user_pool: $user_pool, merchant_uid: $merchant_uid) {
    email
    full_name
    phone
    user_status
    username
    __typename
  }
}
` as GeneratedQuery<APITypes.UsersQueryVariables, APITypes.UsersQuery>;
export const webhooks = /* GraphQL */ `query Webhooks($endpoint: String) {
  webhooks(endpoint: $endpoint) {
    endpoint
    is_active
    name
    __typename
  }
}
` as GeneratedQuery<APITypes.WebhooksQueryVariables, APITypes.WebhooksQuery>;
export const webhookEvents = /* GraphQL */ `query WebhookEvents(
  $id: ID
  $endpoint: String
  $result: WebhookNotificationResult
  $last_evaluated_key: String
  $limit: Int
) {
  webhookEvents(
    id: $id
    endpoint: $endpoint
    result: $result
    last_evaluated_key: $last_evaluated_key
    limit: $limit
  ) {
    events {
      endpoint
      error
      event
      finished_at
      id
      request
      response
      result
      started_at
      status_code
      __typename
    }
    last_evaluated_key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WebhookEventsQueryVariables,
  APITypes.WebhookEventsQuery
>;
