/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../types';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAuthorization = /* GraphQL */ `mutation CreateAuthorization(
  $merchant_uid: String!
  $sale_id: String
  $amount: Int!
  $payment_method_id: String
  $payment_method: PaymentMethodInput
  $invoice_id: String
  $fee: Int
  $fee_mode: FeeMode
  $account_code: String
  $reference: String
  $metadata: AWSJSON
  $health_expense_type: HealthExpenseType
  $digital_wallet: DigitalWalletInput
  $additional_purchase_data: AdditionalPurchaseDataInput
  $one_time_use_token: Boolean
) {
  createAuthorization(
    merchant_uid: $merchant_uid
    sale_id: $sale_id
    amount: $amount
    payment_method_id: $payment_method_id
    payment_method: $payment_method
    invoice_id: $invoice_id
    fee: $fee
    fee_mode: $fee_mode
    account_code: $account_code
    reference: $reference
    metadata: $metadata
    health_expense_type: $health_expense_type
    digital_wallet: $digital_wallet
    additional_purchase_data: $additional_purchase_data
    one_time_use_token: $one_time_use_token
  ) {
    account_code
    additional_purchase_data {
      level3_data_line_item {
        item_code
        item_description
        item_qty_exp
        prod_code
        qty
        tax_amount
        tax_ind
        tax_rate
        tax_rt_exp
        tax_type_id
        unit_cost
        unit_of_msure
        __typename
      }
      level3_data_summary {
        dest_postal_code
        discnt_amt
        duty_amt
        frght_amt
        order_num
        prod_desc
        purch_idfr
        tax_amt
        tax_ind
        __typename
      }
      __typename
    }
    amount
    authorization_date
    authorization_id
    avs_status
    captured_amount
    currency
    device_id
    expiration_date
    failure_reasons
    fee_mode
    fees
    invoice {
      account_code
      created_date
      currency
      due_by
      fee_mode
      invoice_amount
      invoice_date
      invoice_description
      invoice_id
      invoice_name
      merchant_invoice_number
      merchant_uid
      metadata
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      reference
      settings {
        accepted_payment_methods {
          ach
          card
          cash
          __typename
        }
        is_secure
        require_payor_address
        security_pin
        __typename
      }
      status
      total_paid_amount
      __typename
    }
    merchant_uid
    metadata
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    reference
    sale_id
    status
    timezone
    transaction_id
    updated_row_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuthorizationMutationVariables,
  APITypes.CreateAuthorizationMutation
>;
export const createBackOfficeKey = /* GraphQL */ `mutation CreateBackOfficeKey(
  $merchant_uid: String!
  $entity_level: BackofficeKeyEntityLevel!
  $key_name: String
) {
  createBackOfficeKey(
    merchant_uid: $merchant_uid
    entity_level: $entity_level
    key_name: $key_name
  ) {
    api_key
    created_date
    enabled
    entity_level
    key_name
    last_accessed_date
    merchant_uid
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBackOfficeKeyMutationVariables,
  APITypes.CreateBackOfficeKeyMutation
>;
export const createBarcode =
  /* GraphQL */ `mutation CreateBarcode($input: BarcodeInput!) {
  createBarcode(input: $input) {
    account_code
    amount
    barcode_id
    barcode_url
    email_or_phone
    expiration_date
    fees
    full_name
    invoice_id
    merchant_uid
    metadata
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    payor_id
    reference
    sale_id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateBarcodeMutationVariables,
    APITypes.CreateBarcodeMutation
  >;
export const createCardPresentCompletion =
  /* GraphQL */ `mutation CreateCardPresentCompletion(
  $status: TransactionStatus!
  $amount: Int
  $card_brand: String
  $last_four: String
  $service_fee: Int
  $transaction_id: String!
  $created_at: AWSDateTime
  $failure_reason: [String]
) {
  createCardPresentCompletion(
    status: $status
    amount: $amount
    card_brand: $card_brand
    last_four: $last_four
    service_fee: $service_fee
    transaction_id: $transaction_id
    created_at: $created_at
    failure_reason: $failure_reason
  ) {
    amount
    card_brand
    created_at
    currency
    failure_reason
    last_four
    service_fee
    status
    transaction_id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateCardPresentCompletionMutationVariables,
    APITypes.CreateCardPresentCompletionMutation
  >;
export const createCapture = /* GraphQL */ `mutation CreateCapture(
  $merchant_uid: String!
  $authorization_id: String!
  $amount: Int!
  $fee: Int
  $send_receipt: Boolean
  $receipt_description: String
  $allow_reauth: Boolean
  $allow_exceeded_amount: Boolean
) {
  createCapture(
    merchant_uid: $merchant_uid
    authorization_id: $authorization_id
    amount: $amount
    fee: $fee
    send_receipt: $send_receipt
    receipt_description: $receipt_description
    allow_reauth: $allow_reauth
    allow_exceeded_amount: $allow_exceeded_amount
  ) {
    account_code
    ach_return_details {
      return_code
      return_details
      transfer_type
      __typename
    }
    additional_purchase_data {
      level3_data_line_item {
        item_code
        item_description
        item_qty_exp
        prod_code
        qty
        tax_amount
        tax_ind
        tax_rate
        tax_rt_exp
        tax_type_id
        unit_cost
        unit_of_msure
        __typename
      }
      level3_data_summary {
        dest_postal_code
        discnt_amt
        duty_amt
        frght_amt
        order_num
        prod_desc
        purch_idfr
        tax_amt
        tax_ind
        __typename
      }
      __typename
    }
    authorization_id
    avs_status
    currency
    device_id
    dispute_status
    failure_reasons
    fee_mode
    fees
    flag_for_review
    gross_amount
    invoice {
      account_code
      created_date
      currency
      due_by
      fee_mode
      invoice_amount
      invoice_date
      invoice_description
      invoice_id
      invoice_name
      merchant_invoice_number
      merchant_uid
      metadata
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      reference
      settings {
        accepted_payment_methods {
          ach
          card
          cash
          __typename
        }
        is_secure
        require_payor_address
        security_pin
        __typename
      }
      status
      total_paid_amount
      __typename
    }
    is_settled
    merchant {
      ach_active
      card_active
      cash_active
      country_code
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
      updated_row_at
      __typename
    }
    merchant_uid
    metadata
    net_amount
    parent_id
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    recurring {
      account_code
      amount_per_payment
      created_date
      currency
      fee_mode
      fee_per_payment
      is_active
      is_processing
      recurring_id
      merchant_uid
      metadata
      mute_all_emails
      next_payment_date
      payment_interval
      payment_method {
        address_line1
        address_line2
        bank_account_type
        bank_code
        barcode_id
        card_brand
        card_type
        city
        country
        exp_date
        full_name
        is_active
        issuing_country_code
        last_four
        merchant_uid
        metadata
        payment_method_id
        payment_type
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        postal_code
        region
        wallet_type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      prev_payment_date
      recurring_description
      recurring_name
      reference
      remaining_payments
      status
      total_amount_per_payment
      __typename
    }
    reference
    refund_reason {
      reason_code
      reason_details
      __typename
    }
    refund_voidable
    refunded_amount
    sale_id
    settlement_batch
    status
    timezone
    transaction_date
    transaction_id
    transaction_type
    updated_row_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCaptureMutationVariables,
  APITypes.CreateCaptureMutation
>;
export const createCardPresentPayment =
  /* GraphQL */ `mutation CreateCardPresentPayment(
  $merchant_uid: String!
  $device_id: String!
  $payor_id: String
  $payor: PayorInput
  $amount: Int!
  $recurring_id: String
  $invoice_id: String
  $fee: Int
  $fee_mode: FeeMode
  $payment_parameters_name: String
  $account_code: String
  $reference: String
  $send_receipt: Boolean
  $receipt_description: String
  $timezone: String
  $metadata: AWSJSON
) {
  createCardPresentPayment(
    merchant_uid: $merchant_uid
    device_id: $device_id
    payor_id: $payor_id
    payor: $payor
    amount: $amount
    recurring_id: $recurring_id
    invoice_id: $invoice_id
    fee: $fee
    fee_mode: $fee_mode
    payment_parameters_name: $payment_parameters_name
    account_code: $account_code
    reference: $reference
    send_receipt: $send_receipt
    receipt_description: $receipt_description
    timezone: $timezone
    metadata: $metadata
  )
}
` as GeneratedMutation<
    APITypes.CreateCardPresentPaymentMutationVariables,
    APITypes.CreateCardPresentPaymentMutation
  >;
export const createDevice =
  /* GraphQL */ `mutation CreateDevice($input: DeviceInput!) {
  createDevice(input: $input) {
    device_id
    device_description
    device_name
    device_properties
    is_active
    merchant_uid
    processor
    processor_device_id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateDeviceMutationVariables,
    APITypes.CreateDeviceMutation
  >;
export const createInvoice =
  /* GraphQL */ `mutation CreateInvoice($input: InvoiceInput!) {
  createInvoice(input: $input) {
    account_code
    created_date
    currency
    due_by
    fee_mode
    invoice_amount
    invoice_date
    invoice_description
    invoice_id
    invoice_name
    merchant_invoice_number
    merchant_uid
    metadata
    offline_transactions {
      amount
      instance_id
      invoice_id
      note
      transaction_date
      type
      __typename
    }
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    reference
    settings {
      accepted_payment_methods {
        ach
        card
        cash
        __typename
      }
      is_secure
      require_payor_address
      security_pin
      __typename
    }
    status
    total_paid_amount
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvoiceMutationVariables,
    APITypes.CreateInvoiceMutation
  >;
export const createInvoiceEmail =
  /* GraphQL */ `mutation CreateInvoiceEmail($invoice_id: String!) {
  createInvoiceEmail(invoice_id: $invoice_id)
}
` as GeneratedMutation<
    APITypes.CreateInvoiceEmailMutationVariables,
    APITypes.CreateInvoiceEmailMutation
  >;
export const createMerchant = /* GraphQL */ `mutation CreateMerchant(
  $merchant_name: String!
  $parent_merchant_uid: String
  $user: MerchantUserInput
) {
  createMerchant(
    merchant_name: $merchant_name
    parent_merchant_uid: $parent_merchant_uid
    user: $user
  ) {
    ach_active
    card_active
    cash_active
    country_code
    is_system
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
    updated_row_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMerchantMutationVariables,
  APITypes.CreateMerchantMutation
>;
export const createMerchantOnboarding =
  /* GraphQL */ `mutation CreateMerchantOnboarding(
  $merchant_onboarding: MerchantOnboardingInput!
  $support_note: SupportNoteInput
  $is_form_completed: Boolean!
) {
  createMerchantOnboarding(
    merchant_onboarding: $merchant_onboarding
    support_note: $support_note
    is_form_completed: $is_form_completed
  )
}
` as GeneratedMutation<
    APITypes.CreateMerchantOnboardingMutationVariables,
    APITypes.CreateMerchantOnboardingMutation
  >;
export const createMerchantOnboardingDocumentSubmission =
  /* GraphQL */ `mutation CreateMerchantOnboardingDocumentSubmission(
  $merchant_uid: ID!
  $ticket_id: ID!
  $attachment_ids: [ID]!
  $processor: String!
) {
  createMerchantOnboardingDocumentSubmission(
    merchant_uid: $merchant_uid
    ticket_id: $ticket_id
    attachment_ids: $attachment_ids
    processor: $processor
  )
}
` as GeneratedMutation<
    APITypes.CreateMerchantOnboardingDocumentSubmissionMutationVariables,
    APITypes.CreateMerchantOnboardingDocumentSubmissionMutation
  >;
export const createMerchantOnboardingStatusChange =
  /* GraphQL */ `mutation CreateMerchantOnboardingStatusChange(
  $merchant_uid: ID!
  $want_to_unlock_data: Boolean
  $want_to_ask_for_docs: Boolean
) {
  createMerchantOnboardingStatusChange(
    merchant_uid: $merchant_uid
    want_to_unlock_data: $want_to_unlock_data
    want_to_ask_for_docs: $want_to_ask_for_docs
  )
}
` as GeneratedMutation<
    APITypes.CreateMerchantOnboardingStatusChangeMutationVariables,
    APITypes.CreateMerchantOnboardingStatusChangeMutation
  >;
export const createMerchantOnboardingSubmission =
  /* GraphQL */ `mutation CreateMerchantOnboardingSubmission(
  $merchant_uid: ID!
  $processor: String!
) {
  createMerchantOnboardingSubmission(
    merchant_uid: $merchant_uid
    processor: $processor
  )
}
` as GeneratedMutation<
    APITypes.CreateMerchantOnboardingSubmissionMutationVariables,
    APITypes.CreateMerchantOnboardingSubmissionMutation
  >;
export const createNativeApp =
  /* GraphQL */ `mutation CreateNativeApp($input: NativeAppInput!) {
  createNativeApp(input: $input)
}
` as GeneratedMutation<
    APITypes.CreateNativeAppMutationVariables,
    APITypes.CreateNativeAppMutation
  >;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $merchant_uid: ID!
  $context_id: NotificationContextId!
  $primary_value: String
  $secondary_value: String
  $error: String
) {
  createNotification(
    merchant_uid: $merchant_uid
    context_id: $context_id
    primary_value: $primary_value
    secondary_value: $secondary_value
    error: $error
  ) {
    context_id
    error
    merchant_uid
    primary_value
    secondary_value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const createOfflineTransaction =
  /* GraphQL */ `mutation CreateOfflineTransaction($input: OfflineTransactionInput!) {
  createOfflineTransaction(input: $input) {
    amount
    instance_id
    invoice_id
    note
    transaction_date
    type
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateOfflineTransactionMutationVariables,
    APITypes.CreateOfflineTransactionMutation
  >;
export const createOneTimePayment =
  /* GraphQL */ `mutation CreateOneTimePayment(
  $merchant_uid: String!
  $amount: Int!
  $payment_method_id: String
  $payment_method: PaymentMethodInput
  $recurring_id: String
  $invoice_id: String
  $fee: Int
  $fee_mode: FeeMode
  $payment_parameters_name: String
  $account_code: String
  $reference: String
  $send_receipt: Boolean
  $receipt_description: String
  $metadata: AWSJSON
  $health_expense_type: HealthExpenseType
  $digital_wallet: DigitalWalletInput
  $additional_purchase_data: AdditionalPurchaseDataInput
) {
  createOneTimePayment(
    merchant_uid: $merchant_uid
    amount: $amount
    payment_method_id: $payment_method_id
    payment_method: $payment_method
    recurring_id: $recurring_id
    invoice_id: $invoice_id
    fee: $fee
    fee_mode: $fee_mode
    payment_parameters_name: $payment_parameters_name
    account_code: $account_code
    reference: $reference
    send_receipt: $send_receipt
    receipt_description: $receipt_description
    metadata: $metadata
    health_expense_type: $health_expense_type
    digital_wallet: $digital_wallet
    additional_purchase_data: $additional_purchase_data
  ) {
    amount
    card_brand
    created_at
    currency
    failure_reason
    last_four
    service_fee
    status
    transaction_id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateOneTimePaymentMutationVariables,
    APITypes.CreateOneTimePaymentMutation
  >;
export const createPaymentLink =
  /* GraphQL */ `mutation CreatePaymentLink($input: PaymentLinkInput!) {
  createPaymentLink(input: $input) {
    accepted_payment_methods
    account_code
    amount
    amount_is_variable
    call_to_action
    created_date
    currency
    custom_success_message
    fee_mode
    is_active
    link_id
    link_name
    link_url
    max_amount
    merchant_uid
    min_amount
    payment_name
    payment_description
    redirect_url
    require_phone
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePaymentLinkMutationVariables,
    APITypes.CreatePaymentLinkMutation
  >;
export const createPaymentMethod = /* GraphQL */ `mutation CreatePaymentMethod(
  $payment_method: PaymentMethodInput!
  $merchant_uid: String!
) {
  createPaymentMethod(
    payment_method: $payment_method
    merchant_uid: $merchant_uid
  ) {
    address_line1
    address_line2
    bank_account_type
    bank_code
    barcode_id
    card_brand
    card_type
    city
    country
    exp_date
    full_name
    is_active
    issuing_country_code
    last_four
    merchant_uid
    metadata
    payment_method_id
    payment_type
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    postal_code
    region
    wallet_type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMethodMutationVariables,
  APITypes.CreatePaymentMethodMutation
>;
export const createPayor =
  /* GraphQL */ `mutation CreatePayor($input: PayorInput!) {
  createPayor(input: $input) {
    address_line1
    address_line2
    city
    country
    email
    full_name
    merchant_uid
    metadata
    payor_id
    phone
    postal_code
    region
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePayorMutationVariables,
    APITypes.CreatePayorMutation
  >;
export const createReceiptEmail = /* GraphQL */ `mutation CreateReceiptEmail(
  $transaction_id: String!
  $receipt_description: String
  $email: AWSEmail
) {
  createReceiptEmail(
    transaction_id: $transaction_id
    receipt_description: $receipt_description
    email: $email
  )
}
` as GeneratedMutation<
  APITypes.CreateReceiptEmailMutationVariables,
  APITypes.CreateReceiptEmailMutation
>;
export const createRecurringPayment =
  /* GraphQL */ `mutation CreateRecurringPayment($input: RecurringPaymentInput!) {
  createRecurringPayment(input: $input) {
    account_code
    amount_per_payment
    created_date
    currency
    fee_mode
    fee_per_payment
    is_active
    is_processing
    recurring_id
    merchant_uid
    metadata
    mute_all_emails
    next_payment_date
    payment_interval
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    prev_payment_date
    recurring_description
    recurring_name
    reference
    remaining_payments
    status
    total_amount_per_payment
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateRecurringPaymentMutationVariables,
    APITypes.CreateRecurringPaymentMutation
  >;
export const cancelRecurringPayment =
  /* GraphQL */ `mutation CancelRecurringPayment($recurring_id: String!) {
  cancelRecurringPayment(recurring_id: $recurring_id)
}
` as GeneratedMutation<
    APITypes.CancelRecurringPaymentMutationVariables,
    APITypes.CancelRecurringPaymentMutation
  >;
export const createRefund = /* GraphQL */ `mutation CreateRefund(
  $transaction_id: String!
  $amount: Int!
  $refund_reason: RefundReasonInput!
  $refund_email: String
) {
  createRefund(
    transaction_id: $transaction_id
    amount: $amount
    refund_reason: $refund_reason
    refund_email: $refund_email
  )
}
` as GeneratedMutation<
  APITypes.CreateRefundMutationVariables,
  APITypes.CreateRefundMutation
>;
export const createRetryForFailedRecurringPayment =
  /* GraphQL */ `mutation CreateRetryForFailedRecurringPayment($recurring_id: String!) {
  createRetryForFailedRecurringPayment(recurring_id: $recurring_id)
}
` as GeneratedMutation<
    APITypes.CreateRetryForFailedRecurringPaymentMutationVariables,
    APITypes.CreateRetryForFailedRecurringPaymentMutation
  >;
export const createReversal = /* GraphQL */ `mutation CreateReversal(
  $transaction_id: String!
  $amount: Int!
  $refund_reason: RefundReasonInput!
  $refund_email: String
) {
  createReversal(
    transaction_id: $transaction_id
    amount: $amount
    refund_reason: $refund_reason
    refund_email: $refund_email
  ) {
    is_void
    transaction_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReversalMutationVariables,
  APITypes.CreateReversalMutation
>;
export const createSendDisputeEvidence =
  /* GraphQL */ `mutation CreateSendDisputeEvidence($dispute_id: String!, $merchant_uid: ID) {
  createSendDisputeEvidence(
    dispute_id: $dispute_id
    merchant_uid: $merchant_uid
  )
}
` as GeneratedMutation<
    APITypes.CreateSendDisputeEvidenceMutationVariables,
    APITypes.CreateSendDisputeEvidenceMutation
  >;
export const createSuccessOnboardingEmail =
  /* GraphQL */ `mutation CreateSuccessOnboardingEmail($merchant_uid: ID!) {
  createSuccessOnboardingEmail(merchant_uid: $merchant_uid)
}
` as GeneratedMutation<
    APITypes.CreateSuccessOnboardingEmailMutationVariables,
    APITypes.CreateSuccessOnboardingEmailMutation
  >;
export const createSupportNote = /* GraphQL */ `mutation CreateSupportNote(
  $merchant_uid: ID!
  $ticket_id: ID!
  $support_note: SupportNoteInput!
) {
  createSupportNote(
    merchant_uid: $merchant_uid
    ticket_id: $ticket_id
    support_note: $support_note
  )
}
` as GeneratedMutation<
  APITypes.CreateSupportNoteMutationVariables,
  APITypes.CreateSupportNoteMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $merchant_uid: String!
  $amount: Int!
  $payment_method_id: String
  $payment_method: PaymentMethodInput
  $recurring_id: String
  $invoice_id: String
  $fee: Int
  $fee_mode: FeeMode
  $account_code: String
  $reference: String
  $send_receipt: Boolean
  $receipt_description: String
  $metadata: AWSJSON
  $health_expense_type: HealthExpenseType
  $digital_wallet: DigitalWalletInput
  $additional_purchase_data: AdditionalPurchaseDataInput
  $one_time_use_token: Boolean
) {
  createTransaction(
    merchant_uid: $merchant_uid
    amount: $amount
    payment_method_id: $payment_method_id
    payment_method: $payment_method
    recurring_id: $recurring_id
    invoice_id: $invoice_id
    fee: $fee
    fee_mode: $fee_mode
    account_code: $account_code
    reference: $reference
    send_receipt: $send_receipt
    receipt_description: $receipt_description
    metadata: $metadata
    health_expense_type: $health_expense_type
    digital_wallet: $digital_wallet
    additional_purchase_data: $additional_purchase_data
    one_time_use_token: $one_time_use_token
  ) {
    account_code
    ach_return_details {
      return_code
      return_details
      transfer_type
      __typename
    }
    additional_purchase_data {
      level3_data_line_item {
        item_code
        item_description
        item_qty_exp
        prod_code
        qty
        tax_amount
        tax_ind
        tax_rate
        tax_rt_exp
        tax_type_id
        unit_cost
        unit_of_msure
        __typename
      }
      level3_data_summary {
        dest_postal_code
        discnt_amt
        duty_amt
        frght_amt
        order_num
        prod_desc
        purch_idfr
        tax_amt
        tax_ind
        __typename
      }
      __typename
    }
    authorization_id
    avs_status
    currency
    device_id
    dispute_status
    failure_reasons
    fee_mode
    fees
    flag_for_review
    gross_amount
    invoice {
      account_code
      created_date
      currency
      due_by
      fee_mode
      invoice_amount
      invoice_date
      invoice_description
      invoice_id
      invoice_name
      merchant_invoice_number
      merchant_uid
      metadata
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      reference
      settings {
        accepted_payment_methods {
          ach
          card
          cash
          __typename
        }
        is_secure
        require_payor_address
        security_pin
        __typename
      }
      status
      total_paid_amount
      __typename
    }
    is_settled
    merchant {
      ach_active
      card_active
      cash_active
      country_code
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
      updated_row_at
      __typename
    }
    merchant_uid
    metadata
    net_amount
    parent_id
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    recurring {
      account_code
      amount_per_payment
      created_date
      currency
      fee_mode
      fee_per_payment
      is_active
      is_processing
      recurring_id
      merchant_uid
      metadata
      mute_all_emails
      next_payment_date
      payment_interval
      payment_method {
        address_line1
        address_line2
        bank_account_type
        bank_code
        barcode_id
        card_brand
        card_type
        city
        country
        exp_date
        full_name
        is_active
        issuing_country_code
        last_four
        merchant_uid
        metadata
        payment_method_id
        payment_type
        payor {
          address_line1
          address_line2
          city
          country
          email
          full_name
          merchant_uid
          metadata
          payor_id
          phone
          postal_code
          region
          __typename
        }
        postal_code
        region
        wallet_type
        __typename
      }
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      prev_payment_date
      recurring_description
      recurring_name
      reference
      remaining_payments
      status
      total_amount_per_payment
      __typename
    }
    reference
    refund_reason {
      reason_code
      reason_details
      __typename
    }
    refund_voidable
    refunded_amount
    sale_id
    settlement_batch
    status
    timezone
    transaction_date
    transaction_id
    transaction_type
    updated_row_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const createUser =
  /* GraphQL */ `mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    email
    full_name
    phone
    user_status
    username
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserMutationVariables,
    APITypes.CreateUserMutation
  >;
export const createUserPasswordReset =
  /* GraphQL */ `mutation CreateUserPasswordReset($username: String!, $user_pool: UserPool!) {
  createUserPasswordReset(username: $username, user_pool: $user_pool)
}
` as GeneratedMutation<
    APITypes.CreateUserPasswordResetMutationVariables,
    APITypes.CreateUserPasswordResetMutation
  >;
export const createVoidForAuthorization =
  /* GraphQL */ `mutation CreateVoidForAuthorization(
  $authorization_id: String!
  $void_amount: Int
) {
  createVoidForAuthorization(
    authorization_id: $authorization_id
    void_amount: $void_amount
  )
}
` as GeneratedMutation<
    APITypes.CreateVoidForAuthorizationMutationVariables,
    APITypes.CreateVoidForAuthorizationMutation
  >;
export const createVoidForRefund =
  /* GraphQL */ `mutation CreateVoidForRefund($transaction_id: String!) {
  createVoidForRefund(transaction_id: $transaction_id)
}
` as GeneratedMutation<
    APITypes.CreateVoidForRefundMutationVariables,
    APITypes.CreateVoidForRefundMutation
  >;
export const createWebhook =
  /* GraphQL */ `mutation CreateWebhook($endpoint: String!, $name: String!) {
  createWebhook(endpoint: $endpoint, name: $name)
}
` as GeneratedMutation<
    APITypes.CreateWebhookMutationVariables,
    APITypes.CreateWebhookMutation
  >;
export const updateTransactionInReview =
  /* GraphQL */ `mutation UpdateTransactionInReview(
  $transaction_id: String!
  $action: TransactionReviewAction!
) {
  updateTransactionInReview(transaction_id: $transaction_id, action: $action)
}
` as GeneratedMutation<
    APITypes.UpdateTransactionInReviewMutationVariables,
    APITypes.UpdateTransactionInReviewMutation
  >;
export const deleteBackOfficeKey =
  /* GraphQL */ `mutation DeleteBackOfficeKey($merchant_uid: String!, $key_name: String!) {
  deleteBackOfficeKey(merchant_uid: $merchant_uid, key_name: $key_name)
}
` as GeneratedMutation<
    APITypes.DeleteBackOfficeKeyMutationVariables,
    APITypes.DeleteBackOfficeKeyMutation
  >;
export const deleteDevice =
  /* GraphQL */ `mutation DeleteDevice($merchant_uid: String!, $device_id: String!) {
  deleteDevice(merchant_uid: $merchant_uid, device_id: $device_id)
}
` as GeneratedMutation<
    APITypes.DeleteDeviceMutationVariables,
    APITypes.DeleteDeviceMutation
  >;
export const deleteDisputeEvidence =
  /* GraphQL */ `mutation DeleteDisputeEvidence(
  $dispute_id: String!
  $filename: String!
  $merchant_uid: ID!
) {
  deleteDisputeEvidence(
    dispute_id: $dispute_id
    filename: $filename
    merchant_uid: $merchant_uid
  )
}
` as GeneratedMutation<
    APITypes.DeleteDisputeEvidenceMutationVariables,
    APITypes.DeleteDisputeEvidenceMutation
  >;
export const deleteInvoice =
  /* GraphQL */ `mutation DeleteInvoice($invoice_id: String!) {
  deleteInvoice(invoice_id: $invoice_id)
}
` as GeneratedMutation<
    APITypes.DeleteInvoiceMutationVariables,
    APITypes.DeleteInvoiceMutation
  >;
export const deleteMetadata = /* GraphQL */ `mutation DeleteMetadata(
  $id: String!
  $metadata_associate: MetadataAssociate!
  $merchant_uid: String!
  $metadata_keys: [String]!
) {
  deleteMetadata(
    id: $id
    metadata_associate: $metadata_associate
    merchant_uid: $merchant_uid
    metadata_keys: $metadata_keys
  )
}
` as GeneratedMutation<
  APITypes.DeleteMetadataMutationVariables,
  APITypes.DeleteMetadataMutation
>;
export const deleteNativeApp =
  /* GraphQL */ `mutation DeleteNativeApp($merchant_uid: String!, $app_id: String!) {
  deleteNativeApp(merchant_uid: $merchant_uid, app_id: $app_id)
}
` as GeneratedMutation<
    APITypes.DeleteNativeAppMutationVariables,
    APITypes.DeleteNativeAppMutation
  >;
export const deleteUser =
  /* GraphQL */ `mutation DeleteUser($username: String!, $user_pool: UserPool!) {
  deleteUser(username: $username, user_pool: $user_pool)
}
` as GeneratedMutation<
    APITypes.DeleteUserMutationVariables,
    APITypes.DeleteUserMutation
  >;
export const deleteWebhook =
  /* GraphQL */ `mutation DeleteWebhook($endpoint: String!) {
  deleteWebhook(endpoint: $endpoint)
}
` as GeneratedMutation<
    APITypes.DeleteWebhookMutationVariables,
    APITypes.DeleteWebhookMutation
  >;
export const updateBackOfficeKey = /* GraphQL */ `mutation UpdateBackOfficeKey(
  $merchant_uid: String!
  $key_name: String!
  $enabled: Boolean
) {
  updateBackOfficeKey(
    merchant_uid: $merchant_uid
    key_name: $key_name
    enabled: $enabled
  )
}
` as GeneratedMutation<
  APITypes.UpdateBackOfficeKeyMutationVariables,
  APITypes.UpdateBackOfficeKeyMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice(
  $merchant_uid: String!
  $device_id: String!
  $device_description: String!
) {
  updateDevice(
    merchant_uid: $merchant_uid
    device_id: $device_id
    device_description: $device_description
  )
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const updateFeeMatrix =
  /* GraphQL */ `mutation UpdateFeeMatrix($fee_matrix: FeeMatrixInput!) {
  updateFeeMatrix(fee_matrix: $fee_matrix) {
    ach {
      merchant_fee {
        basis_points
        fixed
        max_fee
        __typename
      }
      service_fee {
        basis_points
        fixed
        max_fee
        min_fee
        __typename
      }
      __typename
    }
    ach_return_fee
    ach_return_disputed_fee
    business_credit {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    business_debit {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    amex {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      __typename
    }
    card {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    card_account_updater
    cash
    chargeback_fee
    credit_card {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    debit_card {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    discover {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      __typename
    }
    interchange_plus
    international_card_basis
    mastercard {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      __typename
    }
    merchant_uid
    prepaid_card {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      service_fee {
        basis_points
        fixed
        min_fee
        __typename
      }
      __typename
    }
    service_fee_enabled
    visa {
      merchant_fee {
        basis_points
        fixed
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateFeeMatrixMutationVariables,
    APITypes.UpdateFeeMatrixMutation
  >;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $invoice_id: String!
  $invoice_update_input: InvoiceUpdateInput!
) {
  updateInvoice(
    invoice_id: $invoice_id
    invoice_update_input: $invoice_update_input
  )
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const updateMerchantSettings =
  /* GraphQL */ `mutation UpdateMerchantSettings(
  $merchant_uid: ID!
  $settings: MerchantSettingsInput!
) {
  updateMerchantSettings(merchant_uid: $merchant_uid, settings: $settings)
}
` as GeneratedMutation<
    APITypes.UpdateMerchantSettingsMutationVariables,
    APITypes.UpdateMerchantSettingsMutation
  >;
export const updateMetadata = /* GraphQL */ `mutation UpdateMetadata(
  $id: String!
  $metadata_associate: MetadataAssociate!
  $merchant_uid: String!
  $metadata: AWSJSON!
) {
  updateMetadata(
    id: $id
    metadata_associate: $metadata_associate
    merchant_uid: $merchant_uid
    metadata: $metadata
  )
}
` as GeneratedMutation<
  APITypes.UpdateMetadataMutationVariables,
  APITypes.UpdateMetadataMutation
>;
export const updateNativeApp =
  /* GraphQL */ `mutation UpdateNativeApp($input: NativeAppInput!) {
  updateNativeApp(input: $input)
}
` as GeneratedMutation<
    APITypes.UpdateNativeAppMutationVariables,
    APITypes.UpdateNativeAppMutation
  >;
export const updatePaymentLink =
  /* GraphQL */ `mutation UpdatePaymentLink($input: UpdatePaymentLinkInput!) {
  updatePaymentLink(input: $input) {
    accepted_payment_methods
    account_code
    amount
    amount_is_variable
    call_to_action
    created_date
    currency
    custom_success_message
    fee_mode
    is_active
    link_id
    link_name
    link_url
    max_amount
    merchant_uid
    min_amount
    payment_name
    payment_description
    redirect_url
    require_phone
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdatePaymentLinkMutationVariables,
    APITypes.UpdatePaymentLinkMutation
  >;
export const updatePaymentMethodToDisabled =
  /* GraphQL */ `mutation UpdatePaymentMethodToDisabled(
  $merchant_uid: ID!
  $payment_method_id: String!
) {
  updatePaymentMethodToDisabled(
    merchant_uid: $merchant_uid
    payment_method_id: $payment_method_id
  )
}
` as GeneratedMutation<
    APITypes.UpdatePaymentMethodToDisabledMutationVariables,
    APITypes.UpdatePaymentMethodToDisabledMutation
  >;
export const updatePayor =
  /* GraphQL */ `mutation UpdatePayor($payor_id: String!, $payor_data: PayorData!) {
  updatePayor(payor_id: $payor_id, payor_data: $payor_data)
}
` as GeneratedMutation<
    APITypes.UpdatePayorMutationVariables,
    APITypes.UpdatePayorMutation
  >;
export const updateRecurringPayment =
  /* GraphQL */ `mutation UpdateRecurringPayment($input: UpdateRecurringPaymentInput!) {
  updateRecurringPayment(input: $input) {
    account_code
    amount_per_payment
    created_date
    currency
    fee_mode
    fee_per_payment
    is_active
    is_processing
    recurring_id
    merchant_uid
    metadata
    mute_all_emails
    next_payment_date
    payment_interval
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    prev_payment_date
    recurring_description
    recurring_name
    reference
    remaining_payments
    status
    total_amount_per_payment
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateRecurringPaymentMutationVariables,
    APITypes.UpdateRecurringPaymentMutation
  >;
export const updateRecurringPaymentMethod =
  /* GraphQL */ `mutation UpdateRecurringPaymentMethod(
  $recurring_hash: String!
  $payment_method_id: String!
) {
  updateRecurringPaymentMethod(
    recurring_hash: $recurring_hash
    payment_method_id: $payment_method_id
  ) {
    account_code
    amount_per_payment
    created_date
    currency
    fee_mode
    fee_per_payment
    is_active
    is_processing
    recurring_id
    merchant_uid
    metadata
    mute_all_emails
    next_payment_date
    payment_interval
    payment_method {
      address_line1
      address_line2
      bank_account_type
      bank_code
      barcode_id
      card_brand
      card_type
      city
      country
      exp_date
      full_name
      is_active
      issuing_country_code
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      payor {
        address_line1
        address_line2
        city
        country
        email
        full_name
        merchant_uid
        metadata
        payor_id
        phone
        postal_code
        region
        __typename
      }
      postal_code
      region
      wallet_type
      __typename
    }
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    prev_payment_date
    recurring_description
    recurring_name
    reference
    remaining_payments
    status
    total_amount_per_payment
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateRecurringPaymentMethodMutationVariables,
    APITypes.UpdateRecurringPaymentMethodMutation
  >;
export const updateWebhook =
  /* GraphQL */ `mutation UpdateWebhook($endpoint: String!, $name: String, $is_active: Boolean) {
  updateWebhook(endpoint: $endpoint, name: $name, is_active: $is_active)
}
` as GeneratedMutation<
    APITypes.UpdateWebhookMutationVariables,
    APITypes.UpdateWebhookMutation
  >;
export const updateTransactionAccountCode =
  /* GraphQL */ `mutation UpdateTransactionAccountCode(
  $transaction_id: String!
  $account_code: String!
) {
  updateTransactionAccountCode(
    transaction_id: $transaction_id
    account_code: $account_code
  )
}
` as GeneratedMutation<
    APITypes.UpdateTransactionAccountCodeMutationVariables,
    APITypes.UpdateTransactionAccountCodeMutation
  >;
