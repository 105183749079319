import { MoveDirection, SortDirection } from './API/types';

export type ParsedResponse<T> =
  | {
      data: T;
      errors: null;
    }
  | {
      data: null;
      errors: Array<string>;
    };

export type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export type ListParams<Off, Filter> = {
  order?: SortDirection;
  offset?: Off | null;
  limit?: number;
  filter?: Filter;
  direction?: MoveDirection;
};

// Function that is set to clean up variable object before sending to server and remove __typename key
// Needs to be recursive to handle nested objects
export const cleanVariables = <T>(variables: T): T => {
  // Make a deep copy of the object
  const cleanedVariables = JSON.parse(JSON.stringify(variables));
  const clean = (obj: any) => {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (key === '__typename') {
          delete obj[key];
        } else {
          clean(obj[key]);
        }
      }
    }
  };
  clean(cleanedVariables);
  return cleanedVariables;
};
