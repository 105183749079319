import { ParsedResponse } from './util';
import { generateClient } from 'aws-amplify/api';
import { nativeApps } from './API/graphql/queries';
import {
  CreateNativeAppMutationVariables,
  NativeApp,
  UpdateNativeAppMutationVariables,
} from './API/types';
import {
  createNativeApp,
  deleteNativeApp,
  updateNativeApp,
} from './API/graphql/mutations';

export const list = async (): Promise<
  ParsedResponse<Array<NativeApp | null>>
> => {
  const client = generateClient();
  try {
    const response = await client.graphql({ query: nativeApps });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.nativeApps!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch transactions'] };
  }
};

export const create = async (
  variables: CreateNativeAppMutationVariables,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  try {
    const response = await client.graphql({
      query: createNativeApp,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to create native app'] };
  }
};

export const update = async (
  variables: UpdateNativeAppMutationVariables,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  try {
    const response = await client.graphql({
      query: updateNativeApp,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to update native app'] };
  }
};

export const deleteApp = async (
  merchant_uid: string,
  app_id: string,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables = {
    merchant_uid: merchant_uid,
    app_id: app_id,
  };
  try {
    const response = await client.graphql({
      query: deleteNativeApp,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.deleteNativeApp!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to delete native app'] };
  }
};
