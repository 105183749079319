import {
  downloadCSV,
  findDateOffset,
  findPaymentMethodLogo,
  formatDate,
  formatDateToISO,
  prepareTransactionsForCSV,
  statusChip,
  validDate,
  createTableAmount,
} from '../../util';
import { OnboardingState } from '../../components/OnboardingCard/model';
import {
  ConjunctiveOperator,
  Merchant,
  MerchantOnboarding,
  MoveDirection,
  Operator,
  QueryPair,
  SortDirection,
  Transaction,
  TransactionType,
  TransactionStatus,
  Transactions,
} from '../../network/API/types';
import { transactions, settlements } from '../../network';
import { ListTransactionsFilter } from '../../network/transactions';
import { ParsedResponse } from '../../network/util';

type PayorKeys = 'full_name' | 'email' | 'phone';

export type PayorOption = {
  label: string;
  value: PayorKeys;
  key: PayorKeys;
};

export const payorHeaderOptions: PayorOption[] = [
  {
    label: 'Customer Name',
    value: 'full_name',
    key: 'full_name',
  },
  {
    label: 'Email',
    value: 'email',
    key: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
    key: 'phone',
  },
];

type DescriptorKeys = 'account_code' | 'reference';
export type DescriptorOption = {
  label: string;
  value: DescriptorKeys;
  key: DescriptorKeys;
};

export const descriptionHeaderOptions: DescriptorOption[] = [
  {
    label: 'Account Code',
    value: 'account_code',
    key: 'account_code',
  },
  {
    label: 'Descriptor',
    value: 'reference',
    key: 'reference',
  },
];

export const generateTableColumns = (
  payorColumn: PayorOption,
  setPayorColumn: (option: PayorOption) => void,
  descriptorColumn: DescriptorOption,
  setDescriptorColumn: (option: DescriptorOption) => void,
) => {
  return [
    {
      className: 'amount',
      label: 'Amount',
      type: 'currency',
      width: 190,
    },
    {
      className: 'status',
      label: 'Status',
      type: 'chip',
      width: 190,
    },
    {
      className: 'payment-method',
      label: 'Payment Method',
      type: 'basic',
      width: 150,
    },
    {
      className: 'payor-column',
      label: payorColumn.label,
      type: 'basic',
      isDynamic: true,
      options: payorHeaderOptions,
      selected: payorColumn,
      onSelect: (item: any) => setPayorColumn(item),
    },
    {
      className: 'descriptor-column',
      label: descriptorColumn.label,
      type: 'basic',
      isDynamic: true,
      options: descriptionHeaderOptions,
      selected: descriptorColumn,
      onSelect: (item: any) => setDescriptorColumn(item),
    },
    {
      className: 'payment-date',
      label: 'Payment Date',
      type: 'basic',
      width: 140,
      minWidth: 140,
    },
    {
      className: 'refund',
      label: '',
      action: true,
      type: 'action',
    },
  ];
};

const refundDisabled = (item: Transaction) => {
  return (
    item.transaction_type! !== TransactionType.DEBIT ||
    [
      TransactionStatus.RETURNED,
      TransactionStatus.REFUNDED,
      TransactionStatus.FAILED,
      TransactionStatus.VOIDED,
    ].includes(item.status!) ||
    item?.payment_method?.payment_type === 'CASH' ||
    ['LOST', 'PENDING', 'INQUIRY'].includes(item.dispute_status ?? '')
  );
};

export const generateTableRows = (
  reports: Transaction[],
  viewTransaction: (transaction: Transaction, showRefund: boolean) => void,
  payorColumn: PayorOption,
  descriptorColumn: DescriptorOption,
  merchant: Merchant | null,
) => {
  return reports.map(item => {
    return {
      columns: [
        {
          className: 'amount numeric',
          content: createTableAmount(
            item.gross_amount ?? 0,
            merchant?.country_code ?? 'USA',
          ),
          type: 'basic',
          grey: ['REFUNDED', 'PARTIALLY_REFUNDED'].includes(item.status ?? ''),
        },
        {
          className: `status`,
          text: statusChip[
            item.status?.toLowerCase() as keyof typeof statusChip
          ]?.text,
          color:
            statusChip[item.status?.toLowerCase() as keyof typeof statusChip]
              ?.color,
          textColor:
            statusChip[item.status?.toLowerCase() as keyof typeof statusChip]
              ?.textColor,
          type: 'chip',
        },
        {
          className: 'payment-method',
          brand: findPaymentMethodLogo(item),
          lastFour: item.payment_method?.last_four,
          type: 'paymentMethod',
        },
        {
          className: 'payor-column',
          content: item.payment_method!.payor![payorColumn.key],
          type: 'basic',
        },
        {
          className: 'descriptor-column',
          content: item[descriptorColumn.key],
          type: 'basic',
        },
        {
          className: 'payment-date',
          content: formatDate(item.transaction_date ?? ''),
          type: 'basic',
        },
        {
          className: 'refund',
          label: 'Refund',
          icon: 'undo',
          action: () => {
            viewTransaction(item, true);
          },
          rowObject: item,
          type: 'action',
          disabled: refundDisabled(item),
        },
      ],
      key: `${item.transaction_id}-row`,
      item: item,
      viewRow: () => {
        viewTransaction(item, false);
      },
    };
  });
};

export const exportCsv = (
  items: { [key: string | number]: Transaction },
  country: string | undefined | null,
) => {
  const transactions = Object.values(items);
  const csvArray = prepareTransactionsForCSV(transactions, country);
  downloadCSV([{ items: csvArray }], `PT-Payments-${formatDate(new Date())}`);
};

export const defaultState = {
  paymentID: '',
  payerID: '',
  descriptor: '',
  startDate: '',
  endDate: '',
  pending: false,
  succeeded: false,
  refunded: false,
  partiallyRefunded: false,
  settled: false,
  failed: false,
  card: false,
  ach: false,
  cash: false,
  accountCode: '',
  dateRange: 'All',
  amount: '',
};

export const raiseTable = (raise: boolean) => {
  if (raise) {
    // @ts-ignore
    document.getElementById('payment-table-container').style.zIndex = '100';
  } else {
    // @ts-ignore
    document.getElementById('payment-table-container').style.zIndex = '0';
  }
};

export const buildQuery = (state: { [key: string]: any } = defaultState) => {
  const transactionQuery: QueryPair[] = [];
  const payorQuery: QueryPair[] = [];
  const paymentMethodQuery: QueryPair[] = [];

  transactionQuery.push({
    key: 'transaction_type',
    in_values: ['DEBIT', 'FAILURE'],
    operator: Operator.IN_LIST,
    conjunctive_operator: ConjunctiveOperator.AND_NEXT,
  });

  if (state.paymentID) {
    transactionQuery.push({
      key: 'transaction_id',
      value: `%${state.paymentID}%`,
      operator: Operator.LIKE,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }
  if (state.payerID) {
    const full_name: QueryPair = {
      key: 'full_name',
      value: `%${state.payerID}%`,
      operator: Operator.LIKE,
      conjunctive_operator: ConjunctiveOperator.OR_NEXT,
    };
    const payerIDArray = [full_name];
    payerIDArray.push({ ...full_name, key: 'email' });
    payerIDArray.push({
      ...full_name,
      key: 'phone',
      conjunctive_operator: ConjunctiveOperator.NONE_NEXT,
    });
    payorQuery.push({
      query_group: payerIDArray,
    });
  }

  if (state.descriptor) {
    transactionQuery.push({
      key: 'reference',
      value: `%${state.descriptor}%`,
      operator: Operator.LIKE,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (validDate(state.startDate)) {
    transactionQuery.push({
      key: 'transaction_date',
      value: formatDateToISO(state.startDate, false),
      operator: Operator.GREATER_EQUAL,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (validDate(state.endDate)) {
    transactionQuery.push({
      key: 'transaction_date',
      value: formatDateToISO(state.endDate, true),
      operator: Operator.LESS_EQUAL,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (
    !validDate(state.startDate) &&
    !validDate(state.endDate) &&
    state.dateRange !== 'All'
  ) {
    transactionQuery.push({
      key: 'transaction_date',
      value: findDateOffset(state.dateRange, false),
      operator: Operator.GREATER_EQUAL,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (state.accountCode) {
    transactionQuery.push({
      key: 'account_code',
      value: state.accountCode,
      operator: Operator.LIKE,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (state.amount) {
    transactionQuery.push({
      key: 'gross_amount',
      value: state.amount.replace(/[^0-9]/g, ''),
      operator: Operator.EQUAL,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (state.card || state.ach || state.cash) {
    const paymentType = [];
    if (state.card) paymentType.push('CARD');
    if (state.ach) paymentType.push('ACH');
    if (state.cash) paymentType.push('CASH');
    paymentMethodQuery.push({
      key: 'payment_type',
      in_values: paymentType,
      operator: Operator.IN_LIST,
    });
  }

  if (
    state.pending ||
    state.succeeded ||
    state.refunded ||
    state.settled ||
    state.failed ||
    state.partiallyRefunded
  ) {
    const status = [];
    if (state.pending) status.push('PENDING');
    if (state.succeeded) status.push('SUCCEEDED');
    if (state.refunded) status.push('REFUNDED');
    if (state.partiallyRefunded) status.push('PARTIALLY_REFUNDED');
    if (state.settled) status.push('SETTLED');
    if (state.failed) status.push('FAILED');
    transactionQuery.push({
      key: 'status',
      in_values: status,
      operator: Operator.IN_LIST,
      conjunctive_operator: ConjunctiveOperator.AND_NEXT,
    });
  }

  if (transactionQuery.length > 0)
    transactionQuery[transactionQuery.length - 1].conjunctive_operator =
      ConjunctiveOperator.NONE_NEXT;

  return { transactionQuery, payorQuery, paymentMethodQuery };
};

const getTwoSundaysAgo = () => {
  const today = new Date();

  // Take the day of week and get date back to Sunday of the previous week
  const startOfPreviousWeek = new Date(
    today.setDate(today.getDate() - today.getDay() - 7),
  );
  // Strip hours, minutes, and seconds off the date
  return new Date(
    startOfPreviousWeek.getFullYear(),
    startOfPreviousWeek.getMonth(),
    startOfPreviousWeek.getDate(),
  );
};

export const getChartData = async () => {
  let stillFetching = true;
  let offset = null;
  let data: Array<Transaction | null> = [];
  while (stillFetching) {
    const variables: ListTransactionsFilter = {
      transactionQuery: [
        {
          key: 'transaction_type',
          in_values: ['DEBIT', 'REVERSAL'],
          operator: Operator.IN_LIST,
          conjunctive_operator: ConjunctiveOperator.AND_NEXT,
        },
        {
          key: 'transaction_date',
          value: formatDateToISO(getTwoSundaysAgo().toString(), false),
          operator: Operator.GREATER_EQUAL,
        },
      ],
    };

    const response: ParsedResponse<Transactions> = await transactions.chartData(
      {
        order: SortDirection.DESC,
        offset: offset || undefined,
        limit: 100,
        filter: variables,
        direction: MoveDirection.FORWARD,
      },
    );

    if (response.errors) {
      console.log(response.errors);
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const items: Array<Transaction | null> = response.data?.items!;
    if (!items) {
      return [];
    }
    data = [...data, ...items];
    const totalRowCount = response.data?.total_row_count ?? 0;
    // If there are more items in the totalRowCount than the current data length, there are more items to fetch
    if (totalRowCount && totalRowCount > data.length) {
      offset = items[items.length - 1];
    } else {
      // Otherwise, there are no more items to fetch
      stillFetching = false;
    }
  }
  return data;
};

export const getPageData = async (results: number | undefined) => {
  const filter = buildQuery();
  const [settlementResponse, transactionResponse, listResponse] =
    await Promise.all([
      settlements.list({
        order: SortDirection.DESC,
        direction: MoveDirection.FORWARD,
      }),
      getChartData(),
      transactions.list({
        order: SortDirection.DESC,
        limit: results,
        filter,
        direction: MoveDirection.FORWARD,
      }),
    ]);
  // Check all responses and return nothing if they failed
  if (settlementResponse.errors) {
    console.log(settlementResponse.errors);
    return undefined;
  }
  if (listResponse.errors) {
    console.log(listResponse.errors);
    return undefined;
  }
  if (!transactionResponse) {
    console.log('Failed to fetch chart data');
    return undefined;
  }
  return {
    settlements: settlementResponse.data.items!,
    transactions: transactionResponse,
    listResponse: listResponse,
  };
};

export const getOnboardingStatus = (
  merchant: Merchant | null,
  onboardingState: MerchantOnboarding | null,
): OnboardingState => {
  if (merchant?.card_active && merchant?.cash_active && merchant?.ach_active) {
    return 'boarded';
  } else if (
    onboardingState?.is_locked === false &&
    merchant?.submitted_onboarding &&
    onboardingState?.needs_docs
  ) {
    return 'need_docs_and_updated_data';
  } else if (onboardingState?.needs_docs) {
    return 'need_docs';
  } else if (
    onboardingState?.is_locked === false &&
    merchant?.submitted_onboarding
  ) {
    return 'need_updated_data';
  } else if (merchant?.submitted_onboarding && onboardingState) {
    return 'pending';
  } else if (merchant?.submitted_onboarding === false && onboardingState) {
    return 'onboarding';
  }
  return 'loading';
};
